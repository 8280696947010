<template>
<div>
<div class="redtitle">
    <span>工作信息</span>
    <span @click="jumpTotextdetail(worktitle)">>>&nbsp;&nbsp;更多</span>
</div>
  <div class="projectintroduct">
      <ul class="workinfo-list">
          <li 
          v-for="(item,index) in list" 
          :key="index"
          @click="$goto('/detailShow',{id:item.id})"
          >
             <p>
                 {{item.title}}
                 <i class="tubiaovideo" v-if="item.type === 'video'"></i>
                 <i class="tubiaopic" v-else-if="item.type === 'pic'"></i>
             </p>
             <span>{{item.publishDate | formateData}}</span>
          </li>
      </ul>
  </div>
</div>
</template>

<script>
export default {
  props: {
    list: {
      type:Array,
      default: () => []
    },
    worktitle: {
      type:String,
      default: () => "红丝带工作信息"
    }
  },
  name: 'Projectintroduct',
  data () {
      return {
          // list : [
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: "video"
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: "pic"  
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: ""  
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: ""  
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: ""  
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: ""  
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: ""  
          //     },
          //     {
          //       content: "世界艾滋病日—“红十字 红丝带”防艾公益宣传唱响四川",
          //       time: "2020.04.20",
          //       type: ""  
          //     }
          // ]
      }
    },
    methods : {
        jumpTotextdetail (worktitle) {
           if (worktitle === '红丝带工作信息') {
            this.$router.push({
            path: '/textDetail',
            query: { name: "工作信息", type: 'zyzfwhszhsd_gzxx'}
           })
           } else if (worktitle === '青少年工作信息') {
            this.$router.push({
            path: '/textDetail',
            query: { name: "工作信息", type: 'zyzfwhszqsn_gzxx'}
           }) 
           }
        }
    }
}
</script>

<style lang="less" scoped>
.redtitle  {
    cursor: pointer;
    margin-top: 53px;
    font-size: 23px;
    color: #333333;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    span {
        &:nth-child(1) {
        display: flex;
        &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background: url('../../../assets/img/title.png') no-repeat center center;
       }
     }
       &:nth-child(2) {
           color: #ABABAB;
           font-size: 17px;
       }
    }
   
}
.projectintroduct {
    height: 400px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 40px 23px 40px 40px;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    .workinfo-list {
        cursor: pointer;
        li {
            display: flex;
            justify-content: space-between;
			padding: 13px 0;
            &:last-child{
                margin-bottom: 0;
            }
            p{
                color: #333333;
				&:hover {
					color: #d1292e;
				}
                display: flex;
                &::before {
                    content: "";
                    display: block;
                    height: 16px;
                    width: 26px;
                    background: url('../../../assets/img/list-little.png') no-repeat center center;
                }
            }
            span {
                color: #ABABAB;
            }
            .tubiaovideo{
                width: 22px;
                height: 18px;
                margin-left: 10px;
                background: url('../../../assets/img/video.png') no-repeat center center;
            }
            .tubiaopic{
                width: 22px;
                height: 18px;
                margin-left: 10px;
                background: url('../../../assets/img/pic.png') no-repeat center center;
            }
        }
    }
}
</style>